import axios from '../uitls/require'

export default {
    getBalanceOverview(query) {
        return axios.post('/admin/balance/purchase-report', query)
    },

    getBalanceDetail(query) {
        return axios.post('/admin/balance/purchase-histories', query)
    },

    overviewExport(query) {
        return axios.post('/admin/balance/export-purchase-histories', query)
    },

    bulkExport(query) {
        return axios.post('/admin/balance/export-histories', query)
    },
    getDownloadCollect(query) {
        return axios.post('/admin/downloads/summary-report', query)
    },
    getDownloadParticular(query) {
        return axios.post('/admin/downloads/detail-report', query)
    },
    downloadDetailExport(query) {
        return axios.post('/admin/downloads/export-detail', query)
    }
}