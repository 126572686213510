<template>
  <div>
    <el-table
      style="width: 100%"
      header-cell-class-name="table-head"
      cell-class-name="table-cell"
      v-loading="tableLoading"
      ref="balanceTable"
      class="data-table"
      :data="dataList"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="125px"></el-table-column>
      <el-table-column
        label="下载时间"
        prop="download_at"
        min-width="15%"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="外部ID"
        prop="asset_uid"
        min-width="15%"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="资产名称"
        prop="name"
        min-width="15%"
        show-overflow-tooltip
      ></el-table-column
      ><el-table-column
        label="资产分类"
        min-width="15%"
        width="250"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span v-if="scope.row.categories.length === 0">--</span>
          <span v-for="(item, index) in scope.row.categories" :key="index">
            {{ item.label }}
            <span
              v-if="
                scope.row.categories.length > 1 &&
                scope.row.categories.length - 1 !== index
              "
              >,</span
            >
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="用户邮箱"
        prop="email"
        min-width="20%"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="资产容量"
        prop="download_size"
        min-width="15%"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="市场"
        prop="market"
        min-width="15%"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ market[scope.row.market] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="ta-r mg-t-18">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/uitls/event-bus";
import api from "@/api/stats";
export default {
  data() {
    return {
      total: 0,
      pageSize: 10,
      currentPage: 1,
      dataList: [],
      filter: {},
      market: {
        US: "北美",
      },
      tableLoading: false,
    };
  },
  computed: {
    queryInfo() {
      const query = {
        limit: this.pageSize,
        page: this.currentPage,
        filter: this.filter,
      };
      return query;
    },
  },
  methods: {
    init(val) {
      this.filter = val;
      this.currentPage = 1;
      this.getParticularsList();
    },
    getParticularsList() {
      this.tableLoading = true;
      api.getDownloadParticular(this.queryInfo).then((res) => {
        if (res.data.code === 10200) {
          this.dataList = res.data.data.items;
          this.total = res.data.data.total;
        } else {
          this.$$error(res.data.messge);
        }
        this.tableLoading = false;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getParticularsList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getParticularsList();
    },
    handleSelectionChange(val) {
      this.$emit("selectItems", val);
    },
  },
  created() {
    // EventBus.$on("updateParticulars", (val) => {
    //   this.filter = val;
    //   this.currentPage = 1;
    //   this.init();
    // });
  },
//   activated() {
//     if (this.$route.meta.keepAlive) {
//       this.init();
//     }
//   },
};
</script>
