<template>
  <div>
    <el-table
      style="width: 100%;"
      :data="listData"
      class="data-table"
      header-cell-class-name="table-head"
      cell-class-name="table-cell"
      v-loading="tableLoading"
      ref="balanceTable"
      :default-sort="{ prop: 'date', order: 'descending' }"
    >
      <el-table-column label="汇总时间" prop="download_date" min-width="20%" show-overflow-tooltip></el-table-column>
      <el-table-column label="市场" prop="market" min-width="20%" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{market[scope.row.market]}}</span>
        </template>
      </el-table-column>
      <el-table-column label="下载次数" prop="download_times" min-width="20%" show-overflow-tooltip></el-table-column>
      <el-table-column label="下载容量" prop="download_size" min-width="20%" show-overflow-tooltip></el-table-column>
      <el-table-column>
        <template slot="header">
          <div style="padding-left:22px !important;">操作</div>
        </template>
        <template slot-scope="scope">
          <div class="icon-btn">
            <el-button class="preview-color" size="mini" type="text" @click="handleView(scope.row)" v-if="hasResource('download_detail_report')" >
              <i class="iconfont">&#xe662;</i> 查看明细
            </el-button>
            <el-button
              class="preview-color"
              size="mini"
              type="text"
              @click="handleExport(scope.row)"
              :loading="scope.row.btnLoading"
               v-if="hasResource('download_detail_report_export')"
            >
              <i class="iconfont">&#xe65e;</i>导出明细
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div class="ta-r mg-t-18">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import api from "@/api/stats";
import { formatDate } from "@/uitls/date";
// import { EventBus } from "@/uitls/event-bus";
export default {
  data() {
    return {
      listData: [],
      pageSize: 10,
      currentPage: 1,
      filter: {},
      tableLoading: false,
      market: {
        US: "北美",
      },
      monthFrame: {},
      timeFrame: {},
      total: 0,
    };
  },
  computed: {
    queryInfo() {
      const query = {
        limit: this.pageSize,
        page: this.currentPage,
        filter: this.filter,
      };
      return query;
    },
  },
  methods: {
    init(val) {
      this.filter = val;
      this.currentPage = 1;
      this.getDownloadCollect();
    },
    getDownloadCollect() {
      this.tableLoading = true;
      api.getDownloadCollect(this.queryInfo).then((res) => {
        if (res.data.code === 10200) {
          this.listData = res.data.data.items.map(item=>{
            item.btnLoading=false;
            return item
          });
          this.total = res.data.data.total;
        } else {
          this.$$error(res.data.message);
        }
        this.tableLoading = false;
      });
    },
    handleView(row) {
      this.formatTimeFrame(row);
      if (row.download_date.length === 8) {
        this.$router.push({
          path: "/stats/download-particulars",
          query: {
            monthFilter: JSON.stringify(this.monthFrame),
          },
        });
      } else {
        this.$router.push({
          path: "/stats/download-particulars",
          query: {
            timeFilter: JSON.stringify(this.timeFrame),
          },
        });
      }
    },
    handleExport(row) {
      row.btnLoading=true;
      this.formatTimeFrame(row);
      if (row.download_date.length === 8) {
        let data = {
          time: this.monthFrame,
        };
        api.downloadDetailExport(data).then((res) => {
          if (res.data.code === 10200) {
            const url = res.data.data.export_url;
            this.downloadFile(url);
            this.$nextTick(() => {
              this.$$success("已导出");
            });
          } else {
            this.$$error(res.data.message);
          }
          row.btnLoading=false;
        });
      } else {
        let data = {
          time: this.timeFrame,
        };
        api.downloadDetailExport(data).then((res) => {
          if (res.data.code === 10200) {
            const url = res.data.data.export_url;
            this.downloadFile(url);
            this.$nextTick(() => {
              this.$$success("已导出");
            });
          } else {
            this.$$error(res.data.message);
          }
          row.btnLoading=false;
        });
      }
    },
    formatTimeFrame(row) {
      if (row.download_date.length === 8) {
        const dateInfo = row.download_date
          .replace("年", "-")
          .replace("月", "")
          .split("-");
        const start = new Date(dateInfo[0], dateInfo[1] - 1);
        const end = new Date(dateInfo[0], dateInfo[1], 0);
        this.monthFrame = {
          from: formatDate(start, "yyyy-MM"),
          to: formatDate(end, "yyyy-MM"),
        };
      } else {
        const dateInfo = row.download_date
          .replace("年", "-")
          .replace("月", "-")
          .replace("日", "")
          .split("-");

        const start = new Date(dateInfo[0], dateInfo[1] - 1, dateInfo[2]);
        this.timeFrame = {
          from: formatDate(start, "yyyy-MM-dd"),
          to: formatDate(start, "yyyy-MM-dd"),
        };
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDownloadCollect();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDownloadCollect();
    },
  },
//   created() {
//     EventBus.$on("updateFilter", (val) => {
//       this.filter = val;
//       this.currentPage = 1;
//       this.init();
//     });
//   },
//   activated() {
//     if (this.$route.meta.keepAlive) {
//       this.init();
//     }
//   },
};
</script>
