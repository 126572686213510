<template>
  <div class="dis-fix detail-search-content">
    <el-form :model="queryForm" :inline="true">
      <el-form-item label="月份查询" prop="month_range">
        <el-date-picker
          v-model="queryForm.month_range"
          type="monthrange"
          range-separator="~"
          style="width: 170px"
          value-format="yyyy-MM"
          format="yyyy.MM"
          prefix-icon="none"
          :clearable="false"
          start-placeholder="请选择"
          end-placeholder="请选择"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="日期查询" prop="day_range">
        <el-date-picker
          v-model="queryForm.day_range"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          format="yyyy.MM.dd"
          prefix-icon="none"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="外部ID" prop="market">
        <el-input
          placeholder="请输入资产名称\外部ID"
          style="width: 168px"
          v-model="queryForm.asset_uid_name"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="资产分类">
        <el-cascader
          v-model="category_id"
          :props="defaultParams"
          :options="categoryOptions"
          @change="handleChange"
          @remove-tag="removeTag"
          ref="cascaderAddr"
        ></el-cascader>
      </el-form-item> -->
      <!-- <el-form-item label="市场" prop="market">
        <el-select v-model="queryForm.market">
          <el-option
            v-for="(item, idx) in markets"
            :key="idx"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="用户名称" prop="market">
        <el-input
          placeholder="请输入用户名\邮箱"
          style="width: 168px"
          v-model="queryForm.username_email"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="text-btn">
      <span class="serach-button" @click="send">查询</span>
      <span class="clear-button" @click="clear">清空</span>
    </div>
  </div>
</template>

<script>
// import { EventBus } from "@/uitls/event-bus";
// import api from "../../../../api/assets";
export default {
  data() {
    return {
      queryForm: {
        month_range: [],
        day_range: [],
        asset_uid_name: "",
        username_email: "",
        market: "",
        // category_id: [],
      },
      markets: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "北美",
          value: "US",
        },
      ],
    //   defaultParams: {
    //     label: "name",
    //     value: "id",
    //     children: "children",
    //     // checkStrictly: true,
    //     multiple: true,
    //   },
    //   categoryOptions: [],
    //   category_id: [],
      changeTrue: false,
    };
  },
  methods: {
    init() {
      const monthFilter = this.$route.query.monthFilter;
      const timeFilter = this.$route.query.timeFilter;
      if (monthFilter) {
        let formattedFilter = JSON.parse(monthFilter);
        this.queryForm.month_range = [formattedFilter.from, formattedFilter.to];
      } else if (timeFilter) {
        let formattedFilter = JSON.parse(timeFilter);
        this.queryForm.day_range = [formattedFilter.from, formattedFilter.to];
      } else {
        this.queryForm.month_range = [];
        this.queryForm.day_range = [];
      }
      this.send();
    //   this.category();
    },
    // category() {
    //   api.categroy().then((res) => {
    //     if (res.data.code === 10200) {
    //       this.categoryOptions = res.data.data.children;
    //       // this.categoryOptions.unshift({ id: "", name: "全部" });
    //     }
    //   });
    // },
    send() {
      const form = { ...this.queryForm };
      const createdTime = this.queryForm.month_range;
      const time = this.queryForm.day_range;
      form.month_range = {
        from: createdTime[0],
        to: createdTime[1],
      };
      form.day_range = {
        from: time[0],
        to: time[1],
      };
      form.market = this.queryForm.market;
      form.asset_uid_name = this.queryForm.asset_uid_name;
      form.username_email = this.queryForm.username_email;
      this.$emit('changeList', form)
    //   EventBus.$emit("updateParticulars", form);
    },
    // handleChange(val) {
    //   if (this.changeTrue) {
    //     this.changeTrue = false;
    //     return;
    //   }
    //   this.queryForm.category_id = [];
    //   this.$refs.cascaderAddr.getCheckedNodes().forEach((item, index) => {
    //     if (item.checked == true) {
    //       this.queryForm.category_id.push(item.value);
    //     }
    //   });
    //   this.queryForm.category_id = Array.from(
    //     new Set(this.queryForm.category_id)
    //   );
    // },
    // removeTag(val) {
    //   this.changeTrue = true;
    //   this.$refs.cascaderAddr.getCheckedNodes().forEach((item, index) => {
    //     val.forEach((el) => {
    //       this.queryForm.category_id.forEach((e, index) => {
    //         if (el === e) {
    //           this.queryForm.category_id.splice(index, 1);
    //         }
    //       });
    //     });
    //   });
    // },
    clear() {
      this.queryForm = {
        month_range: [],
        day_range: [],
        asset_uid_name: "",
        username_email: "",
        market: "",
        // category_id: [],
      };
    //   this.category_id = [""];
    },
  },
  mounted() {
    this.init();
  },
//   activated() {
//     if (this.$route.meta.keepAlive) {
//       this.init();
//     }
//   },
};
</script>
