<template>
  <div class="search-form">
    <div class="dis-fix">
      <el-form ref="filterRef" :model="queryForm" :inline="true">
        <el-form-item label="月份查询" prop="month_range">
          <el-date-picker
            v-model="queryForm.month_range"
            type="monthrange"
            range-separator="~"
            style="width: 170px"
            value-format="yyyy-MM"
            format="yyyy.MM"
            prefix-icon="none"
            :clearable="false"
            start-placeholder="请选择"
            end-placeholder="请选择"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="日期查询" prop="day_range">
          <el-date-picker
            v-model="queryForm.day_range"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            format="yyyy.MM.dd"
            prefix-icon="none"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="市场" prop="market">
          <el-select v-model="queryForm.market">
            <el-option
              v-for="(item, idx) in markets"
              :key="idx"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="text-btn">
        <span class="serach-button" @click="send">查询</span>
        <span class="clear-button" @click="clear">清空</span>
      </div>
    </div>
  </div>
</template>

<script>
// import { EventBus } from "@/uitls/event-bus";
export default {
  data() {
    return {
      queryForm: {
        month_range: [],
        day_range: [],
        market: "",
      },
      markets: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "北美",
          value: "US",
        },
      ],
    };
  },
  methods: {
    init() {
      this.clear()
      this.send();
    },
    send() {
      const form = { ...this.queryForm };
      const createdTime = this.queryForm.month_range;
      const time = this.queryForm.day_range;
      form.market = this.queryForm.market;
      form.month_range = {
        from: createdTime[0],
        to: createdTime[1],
      };
      form.day_range = {
        from: time[0],
        to: time[1],
      };
      this.$emit('changeList', form)
    },
    clear() {
      this.queryForm.month_range = [];
      this.queryForm.day_range = [];
      this.queryForm.market = "";
    },
  },
  mounted() {
    this.init();
  },
//   activated() {
//     if (this.$route.meta.keepAlive) {
//       this.init();
//     }
//   },
};
</script>
