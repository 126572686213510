<template>
  <div>
    <collect-search @changeList="handleChangeList" class="mg-t-72"></collect-search>
    <collect-list ref="collect"></collect-list>
  </div>
</template>

<script>
import { collectSearch, collectList } from "../index";
export default {
  components: {
    collectSearch: collectSearch,
    collectList: collectList,
  },
  methods: {
    handleChangeList(params) {
        this.$refs.collect.init(params);
    }
  },
//   mounted(){
//     this.$refs.collect.init()
//   }
};
</script>
